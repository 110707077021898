@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}
